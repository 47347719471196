<template>
  <div class="loading">
    <svg
      width="64"
      height="64"
      viewBox="0 0 24 24"
      class="icon"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    >
      <path
        d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83"
      />
    </svg>
    <p v-if="message">
      {{ message }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'CLoading',
  props: {
    message: {
      type: String,
      default: ''
    }
  }

}
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  inset: 0;

  display: flex;
  flex-direction: column;
  gap: 1.5em;
  justify-content: center;
  align-items: center;

  background: rgb(255 255 255 / 0.85);
}

.icon {
  animation: rotate 2.5s linear infinite;
}

@keyframes rotate {
  to {
    transform: rotate(1turn);
  }
}
</style>
