<template>
  <div class="logo">
    <img
      src="../assets/ras-logo.svg"
      alt="Logo Rudolf Augstein Stiftung"
      width="100"
      height="100"
    >
    <img
      src="../assets/css-logo.jpg"
      alt="Logo Claussen-Simon-Stiftung"
      width="150"
      height="50"
    >
    <img
      src="../assets/ZEITStiftungBucerius_Logo_RGB.jpg"
      alt="Logo ZEIT-Stiftung"
      width="150"
      height="100"
    >
    <img
      src="../assets/HK_Lockup_RGB_Schwarz.svg"
      alt="Logo Hamburgische Kulturstiftung"
      width="200"
      height="100"
    >
  </div>
</template>

<script>
export default {
  name: 'CLogo'
}
</script>

<style lang="scss" scoped>
.logo {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 3em;

  margin-bottom: 3em;
}
</style>
