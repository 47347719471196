<template>
  <div class="logo">
    <img
      src="../assets/ukrainefonds.jpg"
      alt="Logo Ukraine"
      width="400"
      height="100"
    >
  </div>
</template>

<script>
export default {
  name: 'CLogoPage'
}
</script>

<style lang="scss" scoped>
.logo {
  align-items: center;
  text-align: center;
  gap: 3em;
  margin-top:3em;
  margin-bottom: 1em;
}
</style>
